var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',[_c('v-card-text',[_c('p',[_vm._v(" Müşterinin ürününün veya TYAsının süresi dolmak üzere olanlar listelenmektedir. 60 günden az kalanlar ve süresi dolduktan sonra en fazla 10 gün geçmiş olanlar listelenir. ")]),_c('v-data-table',_vm._b({attrs:{"headers":_vm.headersShown,"items":_vm.list,"loading":_vm.isLoading,"options":_vm.options,"server-items-length":_vm.total,"items-per-page":_vm.itemsPerPage,"show-select":false,"hide-default-footer":""},on:{"update:options":function($event){_vm.options=$event},"update:itemsPerPage":function($event){_vm.itemsPerPage=$event},"update:items-per-page":[function($event){_vm.itemsPerPage=$event},_vm.handleItemsPerPageUpdated]},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('rs-table-title-bar',_vm._b({attrs:{"title":"Süresi Dolanlar","icon":"mdi-autorenew","hide-edit":"","search":_vm.search.query},on:{"reload":_vm.loadList,"update:search":function($event){return _vm.$set(_vm.search, "query", $event)}}},'rs-table-title-bar',_vm.titleBarAttrs,false))]},proxy:true},{key:"item.edit",fn:function({ item }){return [_c('router-link',{staticClass:"btn btn-icon btn-sm btn-clean",attrs:{"to":{
            name: 'admin.crm.customers.edit',
            params: { id: item.id },
          }}},[_c('i',{staticClass:"menu-icon mdi mdi-pencil"})])]}},{key:"item.is_active",fn:function({ item }){return [_c('rs-table-cell-boolean',{attrs:{"value":item.is_active}})]}},{key:"item.email",fn:function({ value }){return [_c('rs-table-cell-email',{attrs:{"value":value}})]}},{key:"item.created_at",fn:function({ value }){return [_c('rs-table-cell-date',{attrs:{"show-time":"","value":value}})]}},{key:"item.payment_warning_level",fn:function({ value }){return [(value === 0)?[_c('em',[_vm._v("Yok")])]:(value === 1)?[_vm._v(" Birinci uyarı ")]:(value === 2)?[_c('strong',[_vm._v("İkinci uyarı")])]:(value === 3)?[_c('strong',{staticClass:"text-danger"},[_vm._v("Kapatıldı")])]:_vm._e()]}},{key:"item.customer_group_name",fn:function({ item, value }){return [_c('router-link',{attrs:{"to":{
            name: 'admin.crm.customer-groups.edit',
            params: { id: item.customer_group_id },
          }}},[_vm._v(" "+_vm._s(value)+" ")])]}},{key:"item.product_name",fn:function({ item, value }){return [(item.product_id)?_c('router-link',{attrs:{"to":{
            name: 'admin.products.edit',
            params: { id: item.product_id },
          }}},[_vm._v(" "+_vm._s(value)+" ")]):_c('em',[_vm._v("Yok")])]}},{key:"item.ends_on",fn:function({ value }){return [_c('rs-table-cell-date',{attrs:{"value":value}})]}},{key:"item.type",fn:function({ item }){return [(item.type === 'customer_product_standard')?[_c('router-link',{attrs:{"to":{
              name: 'admin.crm.customers.edit',
              params: { id: item.id },
              hash: '#products',
            }}},[_c('v-chip',{attrs:{"color":"primary","small":""}},[_vm._v("Standart Ürün")])],1)]:(item.type === 'customer_cluster')?[_c('v-chip',{attrs:{"color":"secondary","small":""}},[_vm._v("TYA")])]:_vm._e()]}}]),model:{value:(_vm.selectedItems),callback:function ($$v) {_vm.selectedItems=$$v},expression:"selectedItems"}},'v-data-table',_vm.dataTableAttrs,false))],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }