<template>
  <v-card>
    <v-card-text>
      <p>
        Müşterinin ürününün veya TYAsının süresi dolmak üzere olanlar
        listelenmektedir. 60 günden az kalanlar ve süresi dolduktan sonra en
        fazla 10 gün geçmiş olanlar listelenir.
      </p>
      <v-data-table
        v-bind="dataTableAttrs"
        :headers="headersShown"
        :items="list"
        :loading="isLoading"
        :options.sync="options"
        :server-items-length="total"
        :items-per-page.sync="itemsPerPage"
        v-model="selectedItems"
        @update:items-per-page="handleItemsPerPageUpdated"
        :show-select="false"
        hide-default-footer
      >
        <template v-slot:top>
          <rs-table-title-bar
            title="Süresi Dolanlar"
            icon="mdi-autorenew"
            @reload="loadList"
            hide-edit
            :search.sync="search.query"
            v-bind="titleBarAttrs"
          ></rs-table-title-bar>
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.edit="{ item }">
          <router-link
            class="btn btn-icon btn-sm btn-clean"
            :to="{
              name: 'admin.crm.customers.edit',
              params: { id: item.id },
            }"
          >
            <i class="menu-icon mdi mdi-pencil"></i>
          </router-link>
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.is_active="{ item }">
          <rs-table-cell-boolean :value="item.is_active" />
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.email="{ value }">
          <rs-table-cell-email :value="value" />
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.created_at="{ value }">
          <rs-table-cell-date show-time :value="value" />
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.payment_warning_level="{ value }">
          <template v-if="value === 0">
            <em>Yok</em>
          </template>
          <template v-else-if="value === 1"> Birinci uyarı </template>
          <template v-else-if="value === 2">
            <strong>İkinci uyarı</strong>
          </template>
          <template v-else-if="value === 3">
            <strong class="text-danger">Kapatıldı</strong>
          </template>
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.customer_group_name="{ item, value }">
          <router-link
            :to="{
              name: 'admin.crm.customer-groups.edit',
              params: { id: item.customer_group_id },
            }"
          >
            {{ value }}
          </router-link>
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.product_name="{ item, value }">
          <router-link
            v-if="item.product_id"
            :to="{
              name: 'admin.products.edit',
              params: { id: item.product_id },
            }"
          >
            {{ value }}
          </router-link>
          <em v-else>Yok</em>
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.ends_on="{ value }">
          <rs-table-cell-date :value="value" />
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.type="{ item }">
          <template v-if="item.type === 'customer_product_standard'">
            <router-link
              :to="{
                name: 'admin.crm.customers.edit',
                params: { id: item.id },
                hash: '#products',
              }"
            >
              <v-chip color="primary" small>Standart Ürün</v-chip>
            </router-link>
          </template>
          <template v-else-if="item.type === 'customer_cluster'">
            <v-chip color="secondary" small>TYA</v-chip>
          </template>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import { debounce } from "../../../../core/plugins/debounce";
import { filtersToURL, hasExport, hasPermissions } from "../../../mixins";

export default {
  mixins: [filtersToURL, hasExport, hasPermissions],
  data() {
    return {
      options: {
        sortBy: ["name"],
      },
      headers: [
        {
          text: "",
          value: "edit",
          searchable: false,
          sortable: false,
          width: "30px",
          align: "center",
        },
        {
          text: "Müşteri",
          value: "customer_name",
          searchable: "text",
          width: "200px",
        },
        {
          text: "Bitiş Tarihi",
          value: "ends_on",
          searchable: "date",
        },
        {
          text: "TYA",
          value: "cluster_name",
          searchable: "text",
        },
        {
          text: "Durum",
          value: "type",
          searchable: false,
          align: "center",
        },
      ],
      titleBarAttrs: {
        exportUrl: () => "api-super-admin/customers-expiring",
        exportParams: this.getParams,
      },
    };
  },
  mounted() {
    this.generateExportColumns(this.headers);
  },
  methods: {
    getParams() {
      const params = Object.assign({}, this.buildSearchParams(this.options));
      params.per_page = 1000;

      return params;
    },
    loadList() {
      if (this.isLoading) {
        return;
      }

      this.isLoading = true;
      this.list = [];
      this.footTotals = [];

      const params = this.getParams();

      this.$api
        .query("api-super-admin/customers-expiring", { params })
        .then((response) => {
          this.loadListFromResponse(response);
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => (this.isLoading = false));
    },
  },
  watch: {
    options: {
      handler() {
        this.setURLParams();
      },
      deep: true,
    },
    search: {
      handler: debounce(function () {
        this.options.page = 1;
        this.setURLParams();
      }, 250),
      deep: true,
    },
  },
};
</script>
